<template>

    <div class="container-fluid">
               <!--================================-->
               <!-- User Profile Start -->
               <!--================================-->
        <div class="row no-gutters mg-b-20">
            <div class="col-md-12">
                <div class="card bg-light bd-l-0-force bd-t-0-force bd-r-0-force">
                <div class="card-body pd-b-0">
                    <div class="p-2 mb-3">
                        <div class="mt-3 mb-3 row justify-content-center">
                            <div class="card shadow-none pd-20 mx-auto wd-600 text-center bd-1 align-self-center">
                            <div class="text-center">
                            <img src="https://scola-uploader.sgp1.digitaloceanspaces.com/upload/431f98add25ded6563ab086d/logo_itb_1024.png" class="rounded-circle img-fluid wd-60 mg-y-20" alt="">			   
                            <h5 class="text-center text-dark">Survey Alumni Program Doktor</h5>
                            <hr>
                            </div>
                            <div class="clearfix">
                            <form @submit.prevent="submitForm" enctype="multipart/form-data">
                                    <h6 class="text-left float-left text-dark">Identitas Responsen</h6><br><br>
                                    <label class="text-left float-left">Nama<span class="tx-danger">*</span></label>
                                    <div class="form-group input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text"><i class="fa fa-user"></i></div>
                                        </div>
                                        <input type="text" disabled v-model="userdata.name" class="form-control" required="">
                                    </div>
                                    <label class="text-left float-left">Email<span class="tx-danger">*</span></label>
                                    <div class="form-group input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text"><i class="fa fa-envelope"></i></div>
                                        </div>
                                        <input type="text" v-model="form.email" class="form-control" required="">
                                    </div>
                                    <label class="text-left float-left">Tahun Masuk<span class="tx-danger">*</span></label>
                                    <div class="form-group input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text"><i class="fa fa-calendar"></i></div>
                                        </div>
                                        <input type="text" disabled v-model="profil[0].tahun_masuk" class="form-control" required="">
                                    </div>
                                    <label class="text-left float-left">Tahun Lulus<span class="tx-danger">*</span></label>
                                    <div class="form-group input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text"><i class="fa fa-graduation-cap"></i></div>
                                        </div>
                                        <input type="text" disabled v-model="profil[0].tahun_lulus" class="form-control" required="">
                                    </div>
                                    <label class="text-left float-left">Pengutamaan<span class="tx-danger">*</span></label>
                                    <div class="form-group input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text"><i class="fa fa-check-square"></i></div>
                                        </div>
                                        <input type="text" disabled v-model="profil[0].name" class="form-control" required="">
                                    </div>
                                    <label class="text-left float-left">Pekerjaan</label>
                                    <div class="form-group input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text"><i class="fa fa-link"></i></div>
                                        </div>
                                        <input type="text" v-model="form.pekerjaan" class="form-control">
                                    </div>
                                    <label class="text-left float-left">Nama Instansi Tempat Bekerja atau Usaha Mandiri</label>
                                    <div class="form-group input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text"><i class="fa fa-building"></i></div>
                                        </div>
                                        <input type="text" v-model="form.instansi" class="form-control">
                                    </div>
                                    <hr>
                                    <h6 class="text-left float-left text-dark">Kesesuaian Bidang Pekerjaan</h6><br><br>
                                    <label class="text-left float-left">Apakah menurut Anda bidang pekerjaan saat ini sesuai dengan bidang pendidikan Anda ?</label>
                                    <div class="form-group input-group">
                                        <div class="col-md-4 mg-t-20 mg-lg-t-0">
                                            <div class="text-left float-left custom-control custom-radio">
                                            <input name="kesesuaian" type="radio" value="1" v-model="form.kesesuaian" class="custom-control-input" id="kesesuaian1">
                                            <label class="custom-control-label" for="kesesuaian1">Kesesuaian Tinggi</label>
                                            </div>
                                        </div>
                                        <div class="col-md-4 mg-t-20 mg-lg-t-0">
                                            <div class="text-left float-left custom-control custom-radio">
                                            <input name="kesesuaian" type="radio" value="2" v-model="form.kesesuaian" class="custom-control-input" id="kesesuaian2">
                                            <label class="custom-control-label" for="kesesuaian2">Kesesuaian Sedang</label>
                                            </div>
                                        </div>
                                        <div class="col-md-4 mg-t-20 mg-lg-t-0">
                                            <div class="text-left float-left custom-control custom-radio">
                                            <input name="kesesuaian" type="radio" value="3" v-model="form.kesesuaian" class="custom-control-input" id="kesesuaian3">
                                            <label class="custom-control-label" for="kesesuaian3">Kesesuaian Rendah</label>
                                            </div>
                                        </div>
                                    </div>
                                    <label class="text-left float-left">Berapa waktu yang Anda perlukan setelah lulus hingga memperoleh pekerjaa atau memulai usaha baru ?</label>
                                    <div class="form-group input-group">
                                            <div class="col-md-12 mg-t-20 mg-lg-t-0">
                                                <div class="text-left float-left custom-control custom-radio">
                                                <input name="berkarir" type="radio" value="1" v-model="form.berkarir" class="custom-control-input" id="berkarir1">
                                                <label class="custom-control-label" for="berkarir1">Kurang dari 1 bulan</label>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mg-t-20 mg-lg-t-0">
                                                <div class="text-left float-left custom-control custom-radio">
                                                <input name="berkarir" type="radio" value="2" v-model="form.berkarir" class="custom-control-input" id="berkarir2">
                                                <label class="custom-control-label" for="berkarir2">Antara 1-3 Bulan</label>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mg-t-20 mg-lg-t-0">
                                                <div class="text-left float-left custom-control custom-radio">
                                                <input name="berkarir" type="radio" value="3" v-model="form.berkarir" class="custom-control-input" id="berkarir3">
                                                <label class="custom-control-label" for="berkarir3">Antara 3-6 Bulan</label>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mg-t-20 mg-lg-t-0">
                                                <div class="text-left float-left custom-control custom-radio">
                                                <input name="berkarir" type="radio" value="4" v-model="form.berkarir" class="custom-control-input" id="berkarir4">
                                                <label class="custom-control-label" for="berkarir4">Antara 6-12 Bulan</label>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mg-t-20 mg-lg-t-0">
                                                <div class="text-left float-left text-left float-left custom-control custom-radio">
                                                <input name="berkarir" type="radio" value="5" v-model="form.berkarir" class="custom-control-input" id="berkarir5">
                                                <label class="custom-control-label" for="berkarir5">Lebih dari 12 bulan</label>
                                                </div>
                                            </div>
                                        </div>
                                    <hr>
                                    <h6 class="text-left float-left text-dark">Publikasi Ilmiyah dan Penghargaan</h6><br><br>
                                    <label class="text-left float-left">Apakah selama studi di Doktor Teknik Sipil dan setelah lulus, Anda pernah menulis publikasi ilmiah ? Jika ya, mohon sampaikan link atau judul publikasi yang dimaksud</label>
                                    <div class="form-group input-group">
                                        <input type="text" v-model="form.karya" class="form-control">
                                    </div>
                                    <label class="text-left float-left">Apakah selama studi di Doktor Teknik Sipil dan setelah lulus, Anda pernah mendapatkan penghargaan nasional/internaional ? Jika ya, mohon sampaikan link kegiatannya yang di maksud</label>
                                    <div class="form-group input-group">
                                        <input type="text" v-model="form.kegiatan" class="form-control">
                                    </div>
                                    <hr>
                                    <h6 class="text-left float-left text-dark">Masukan Bagi Prodi Doktor Teknik Sipil</h6><br><br>
                                    <label class="text-left float-left">Kurikulum<span class="tx-danger">*</span></label>
                                    <div class="form-group input-group">
                                        <div class="col-md-3">
                                            <div class="text-left float-left custom-control custom-radio">
                                            <label >Kurang&emsp;&emsp;</label>
                                            </div>
                                        </div>
                                        <div class="col-md-1 mg-t-20 mg-lg-t-0">
                                            <div class="custom-control custom-radio">
                                            <input name="kurikulum" type="radio" value="1" v-model="form.kurikulum" class="custom-control-input" id="kurikulum1">
                                            <label class="custom-control-label" for="kurikulum1">1</label>
                                            </div>
                                        </div>
                                        <div class="col-md-1 mg-t-20 mg-lg-t-0">
                                            <div class="custom-control custom-radio">
                                            <input name="kurikulum" type="radio" value="2" v-model="form.kurikulum" class="custom-control-input" id="kurikulum2">
                                            <label class="custom-control-label" for="kurikulum2">2</label>
                                            </div>
                                        </div>
                                        <div class="col-md-1 mg-t-20 mg-lg-t-0">
                                            <div class="custom-control custom-radio">
                                            <input name="kurikulum" type="radio" value="3" v-model="form.kurikulum" class="custom-control-input" id="kurikulum3">
                                            <label class="custom-control-label" for="kurikulum3">3</label>
                                            </div>
                                        </div>
                                        <div class="col-md-1 mg-t-20 mg-lg-t-0">
                                            <div class="custom-control custom-radio">
                                            <input name="kurikulum" type="radio" value="4" v-model="form.kurikulum" class="custom-control-input" id="kurikulum4">
                                            <label class="custom-control-label" for="kurikulum4">4</label>
                                            </div>
                                        </div>
                                        <div class="col-md-1 mg-t-20 mg-lg-t-0">
                                            <div class="text-left float-left custom-control custom-radio">
                                            <input name="kurikulum" type="radio" value="5" v-model="form.kurikulum" class="custom-control-input" id="kurikulum5">
                                            <label class="custom-control-label" for="kurikulum5">5</label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="text-left float-left custom-control custom-radio">
                                            <label >&emsp;&emsp;Sangat baik</label>
                                            </div>
                                        </div>
                                    </div>
                                    <label class="text-left float-left">Sarana dan Prasarana<span class="tx-danger">*</span></label>
                                    <div class="form-group input-group">
                                        <div class="col-md-3">
                                            <div class="text-left float-left custom-control custom-radio">
                                            <label >Kurang&emsp;&emsp;</label>
                                            </div>
                                        </div>
                                        <div class="col-md-1 mg-t-20 mg-lg-t-0">
                                            <div class="custom-control custom-radio">
                                            <input name="sarana" type="radio" value="1" v-model="form.sarana" class="custom-control-input" id="sarana1">
                                            <label class="custom-control-label" for="sarana1">1</label>
                                            </div>
                                        </div>
                                        <div class="col-md-1 mg-t-20 mg-lg-t-0">
                                            <div class="custom-control custom-radio">
                                            <input name="sarana" type="radio" value="2" v-model="form.sarana" class="custom-control-input" id="sarana2">
                                            <label class="custom-control-label" for="sarana2">2</label>
                                            </div>
                                        </div>
                                        <div class="col-md-1 mg-t-20 mg-lg-t-0">
                                            <div class="custom-control custom-radio">
                                            <input name="sarana" type="radio" value="3" v-model="form.sarana" class="custom-control-input" id="sarana3">
                                            <label class="custom-control-label" for="sarana3">3</label>
                                            </div>
                                        </div>
                                        <div class="col-md-1 mg-t-20 mg-lg-t-0">
                                            <div class="custom-control custom-radio">
                                            <input name="sarana" type="radio" value="4" v-model="form.sarana" class="custom-control-input" id="sarana4">
                                            <label class="custom-control-label" for="sarana4">4</label>
                                            </div>
                                        </div>
                                        <div class="col-md-1 mg-t-20 mg-lg-t-0">
                                            <div class="text-left float-left custom-control custom-radio">
                                            <input name="sarana" type="radio" value="5" v-model="form.sarana" class="custom-control-input" id="sarana5">
                                            <label class="custom-control-label" for="sarana5">5</label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="text-left float-left custom-control custom-radio">
                                            <label >&emsp;&emsp;Sangat baik</label>
                                            </div>
                                        </div>
                                    </div>
                                    <label class="text-left float-left">Staf Akademik (dosen)<span class="tx-danger">*</span></label>
                                    <div class="form-group input-group">
                                        <div class="col-md-3">
                                            <div class="text-left float-left custom-control custom-radio">
                                            <label >Kurang&emsp;&emsp;</label>
                                            </div>
                                        </div>
                                        <div class="col-md-1 mg-t-20 mg-lg-t-0">
                                            <div class="custom-control custom-radio">
                                            <input name="dosen" type="radio" value="1" v-model="form.dosen" class="custom-control-input" id="dosen1">
                                            <label class="custom-control-label" for="dosen1">1</label>
                                            </div>
                                        </div>
                                        <div class="col-md-1 mg-t-20 mg-lg-t-0">
                                            <div class="custom-control custom-radio">
                                            <input name="dosen" type="radio" value="2" v-model="form.dosen" class="custom-control-input" id="dosen2">
                                            <label class="custom-control-label" for="dosen2">2</label>
                                            </div>
                                        </div>
                                        <div class="col-md-1 mg-t-20 mg-lg-t-0">
                                            <div class="custom-control custom-radio">
                                            <input name="dosen" type="radio" value="3" v-model="form.dosen" class="custom-control-input" id="dosen3">
                                            <label class="custom-control-label" for="dosen3">3</label>
                                            </div>
                                        </div>
                                        <div class="col-md-1 mg-t-20 mg-lg-t-0">
                                            <div class="custom-control custom-radio">
                                            <input name="dosen" type="radio" value="4" v-model="form.dosen" class="custom-control-input" id="dosen4">
                                            <label class="custom-control-label" for="dosen4">4</label>
                                            </div>
                                        </div>
                                        <div class="col-md-1 mg-t-20 mg-lg-t-0">
                                            <div class="text-left float-left custom-control custom-radio">
                                            <input name="dosen" type="radio" value="5" v-model="form.dosen" class="custom-control-input" id="dosen5">
                                            <label class="custom-control-label" for="dosen5">5</label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="text-left float-left custom-control custom-radio">
                                            <label >&emsp;&emsp;Sangat baik</label>
                                            </div>
                                        </div>
                                    </div>
                                    <label class="text-left float-left">Proses, suasana, dan standar pembelajaran<span class="tx-danger">*</span></label>
                                    <div class="form-group input-group">
                                        <div class="col-md-3">
                                            <div class="text-left float-left custom-control custom-radio">
                                            <label >Kurang&emsp;&emsp;</label>
                                            </div>
                                        </div>
                                        <div class="col-md-1 mg-t-20 mg-lg-t-0">
                                            <div class="custom-control custom-radio">
                                            <input name="proses" type="radio" value="1" v-model="form.proses" class="custom-control-input" id="proses1">
                                            <label class="custom-control-label" for="proses1">1</label>
                                            </div>
                                        </div>
                                        <div class="col-md-1 mg-t-20 mg-lg-t-0">
                                            <div class="custom-control custom-radio">
                                            <input name="proses" type="radio" value="2" v-model="form.proses" class="custom-control-input" id="proses2">
                                            <label class="custom-control-label" for="proses2">2</label>
                                            </div>
                                        </div>
                                        <div class="col-md-1 mg-t-20 mg-lg-t-0">
                                            <div class="custom-control custom-radio">
                                            <input name="proses" type="radio" value="3" v-model="form.proses" class="custom-control-input" id="proses3">
                                            <label class="custom-control-label" for="proses3">3</label>
                                            </div>
                                        </div>
                                        <div class="col-md-1 mg-t-20 mg-lg-t-0">
                                            <div class="custom-control custom-radio">
                                            <input name="proses" type="radio" value="4" v-model="form.proses" class="custom-control-input" id="proses4">
                                            <label class="custom-control-label" for="proses4">4</label>
                                            </div>
                                        </div>
                                        <div class="col-md-1 mg-t-20 mg-lg-t-0">
                                            <div class="text-left float-left custom-control custom-radio">
                                            <input name="proses" type="radio" value="5" v-model="form.proses" class="custom-control-input" id="proses5">
                                            <label class="custom-control-label" for="proses5">5</label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="text-left float-left custom-control custom-radio">
                                            <label >&emsp;&emsp;Sangat baik</label>
                                            </div>
                                        </div>
                                    </div>
                                    <label class="text-left float-left">Mohon masukan Bapak/Ibu dan rekan-rekan alumni bagi pengembangan Prodi Doktor Teknik Sipil ITB</label>
                                    <div class="form-group input-group">
                                        <textarea v-model="form.masukan" class="form-control"></textarea>
                                    </div>
                                   <div class="input-group mg-y-20">
                                        <button v-if="fetching" type="button" disabled class="disabled col-12 btn btn-primary">Loading (sedang mengirim pemberitahuan ke admin)...</button>
                                        <button v-if="!form.id && !fetching" class="col-12 btn btn-primary">Kirim Survey</button>
                                        <button v-if="form.id && !fetching" class="col-12 btn btn-warning">Perbaharui Survey</button>
                                    </div>
                            </form>
                            </div>
                        </div>
                        
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <!--/ User Profile End -->
    </div>
</template>
<script>
    // @ is an alias to /src
    import { mapState } from 'vuex';

    export default {
        name: 'Beranda',
        components: {
            
        },
        metaInfo: {
            title: 'FTSL',
            titleTemplate: '%s - Profil Mahasiswa'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                search: "",
                profil: [],
                fetching: true,
                checkPassword: 0,
                form: {
                    id : '',
                    email : '',
                    pekerjaan : '',
                    instansi : '',
                    kesesuaian : '',
                    berkarir : '',
                    karya : '',
                    kegiatan : '',
                    kurikulum : '',
                    sarana : '',
                    dosen : '',
                    proses : '',
                    masukan : '',
                }
            }
        },
        mounted() {
            this.$store.dispatch('loadUserData')
        },
        computed: mapState([
            'userdata'
        ]),
        created() {
            this.getData();
        },
        methods: {
            hide_modal() {
                this.$modal.hide('my-modal')
            },
            getData: function() {
                this.listData = [];
                this.fetching = true;
                let uri = this.$apiconfig + 'mahasiswa/profil';
                this.$http.get(uri, {
                    params: {
                        keyword     : '',
                    }
                }).then(res => {					
                    let getResponse = res.data;
                    if (getResponse.status) {
                        this.profil = getResponse.data;
                        this.form   = getResponse.data[0];
                    }
                    this.fetching = false;
                });
            },
            submitForm() {
                this.fetching = true;
                const formData = new FormData();
                formData.append('id', this.form.id);
                formData.append('email', this.form.email);
                formData.append('pekerjaan', this.form.pekerjaan);
                formData.append('instansi', this.form.instansi);
                formData.append('kesesuaian', this.form.kesesuaian);
                formData.append('berkarir', this.form.berkarir);
                formData.append('karya', this.form.karya);
                formData.append('kegiatan', this.form.kegiatan);
                formData.append('kurikulum', this.form.kurikulum);
                formData.append('sarana', this.form.sarana);
                formData.append('dosen', this.form.dosen);
                formData.append('proses', this.form.proses);
                formData.append('masukan', this.form.masukan);

                this.$http.post(this.baseUrl + 'mahasiswa/profil/survey', formData)
                    .then((response) => {
                        if(response.data.status){
                            this.$swal({
                                icon: 'success',
                                title: "success",
                                text: response.data.message,
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.fetching = false;
                            this.$router.push('/progress/kemajuan');
                        }else{
                            this.$swal({
                                icon: 'error',
                                title: "Error",
                                text: response.data.message,
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    });
            },
        }
    }
</script>